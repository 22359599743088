import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
                <h1>Messina Messenger, Living Section</h1>

                <h2>Mayor welcomes Messina troops, citizens to party on the Piazza</h2>

                <p>
                Preparations are busily underway for tonight’s banquet honoring the newly returned soldiers. Hero, daughter of Messina Mayor Leonato and hostess of the event, bustled about with excitement this morning as she determined the placement of floral arrangements and finalized seating charts. 

“It’s going to be such a romantic night,” Hero predicted. 

Around her, workers on ladders hung a canopy of twinkling lights that will emulate the night sky above the cherished Fountain of Orion at the center of the piazza.

The event will feature lavish fare courtesy of local restauranteurs, live performances by the Messina Orchestra and dancing. The evening will end with a brilliant fireworks display.

                </p>
            </div>
        </Layout>
    )
}